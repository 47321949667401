import React, { useState } from 'react'

/* emailjs */
import emailjs from 'emailjs-com'



/* css */
import styles from './Contact.module.css'

/* import images */
import Phone from '../img/phone.png'
import Email from '../img/email.png'
import Address from '../img/address.png'






const Contact = () => {
    const [done, setDone] = useState(false)

    function sendEmail(e) {
        e.preventDefault()

        emailjs.sendForm(
            'service_5u8nzez', 
            'template_xfcn35s', 
            e.target,
            'qGbMhLp7jxv9wzqKH' 
            ).then(res => {
                console.log(res);
                setDone(true)
            }).catch(err => console.log(err))

            e.target.reset()
    }

  return (
    <div className={styles.c}>
       <div className={styles.c_bg}></div>
            <div className={styles.c_wrapper}>
                <div className={styles.c_left}>
                    <h1 className={styles.c_title}>Benimle iletişime geçin</h1>
                    <div className={styles.c_info}>
                        <div className={styles.c_info_item}>
                            <img src={Phone} alt="" className={styles.c_icon} />
                           <a href="tel:+90-532-250-66-14">0532 250 6614</a> 
                        </div>
                    </div>
                    
                    <div className={styles.c_info}>
                        <div className={styles.c_info_item}>
                            <img src={Email} alt="" className={styles.c_icon} />
                            <a href="mailto: volkanulusoy@remaxtoprak.com">volkanulusoy@remaxtoprak.com</a>
                        </div>
                    </div>

                    <div className={styles.c_info}>
                        <div className={styles.c_info_item}>
                            <img src={Address} alt="" className={styles.c_icon} />
                            BARIŞ MAHALLESİ, ALİ ÇEBİ CADDESİ, BEYTOWN APARTMANI, NO:44/1, BEYLİKDÜZÜ / İSTANBUL
                        </div>
                    </div>
                </div>
                <div id='contact' className={styles.c_right}>
                    <p className={styles.c_desc}>
                    Gayrimenkul Almak ya da Satmak mı istiyorsunuz ?    <br />  <b>Bir görüşme planlayın.  </b> 
                    </p>
                    <span className='text-green-400'>{done && "Mesajınız İletildi" }</span>
                    <form onSubmit={sendEmail}>
                        <input type="text" placeholder='Ad Soyad' name='name'  />
                        <input type="email" placeholder='E-mail' name='email'  />
                        <input type="tel" placeholder='Telefon' name='tel' required  />
                        <input type="text" placeholder='Konu' name='subject'  />

                        <textarea name="message" rows="5" placeholder='Mesaj'></textarea>
                        <button type='Submit' value='send'>Gönder</button>
                    </form>
                </div>
            </div>
    </div>
  )
}

export default Contact
